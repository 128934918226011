<template>
  <div
    class="gamelist-head"
    ref="gamelistHead"
    :style="setWidth"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'gamelist-head',

  inject: {
    gamelistConfig: {}
  },

  data () {
    return {
      gamelistHead_w: null
    }
  },

  computed: {
    setWidth () {
      let result
      let width = this.gamelistConfig.body_w
      let gamelistWidth = this.gamelistConfig.gamelist_w

      if (gamelistWidth > width) {
        result = gamelistWidth + 'px'
      } else {
        result = width + 'px'
      }

      return {
        width: result
      }
    }
  }
}
</script>
